<template>
    <ul class="components" id="sidebar">

        <span v-if="currentMenu.menus" v-for="(mainMenu, index) in currentMenu.menus" :key="index">
            <li v-if="mainMenu != null"
                :class="$route.name === mainMenu.route_link || $route.path === mainMenu.route_link ? 'active': ''">
                <div class="accordion accordion-menu" :id="`accordion-menu-${index}`"
                v-if="mainMenu.menus.length > 0">
                    <div class="accordion-item">
                        <div class="accordion-header" :id="`headingOne-${index}`">
                            <a class="d-inline-flex accordion-button" type="button" data-bs-toggle="collapse"
                                :data-bs-target="`#collapseOne-${index}`" aria-expanded="true"
                                :aria-controls="`collapseOne-${index}`">
                                <font-awesome-icon :icon="['fas', `${mainMenu.icon}`]" :class="'my-auto'"></font-awesome-icon>
                                <span class="sidebar-text my-auto">{{ getTranslatedValue(mainMenu.name) }}</span>
                            </a>
                        </div>
                        <ul :id="`collapseOne-${index}`" :class="['setup-submenu accordion-collapse collapse']"
                            :aria-labelledby="`headingOne-${index}`" :data-bs-parent="`#accordion-menu-${index}`">
                            <span v-for="(child, c_index) in mainMenu.menus">
                                <li v-if="child !=null"
                                    :class="isRouteActive(index, child)"
                                    :key="c_index">
                                    <router-link :to="setRouterLink(child)" data-toggle="collapse" aria-expanded="false" class="d-inline-flex w-100">
                                        <font-awesome-icon :icon="['fas', `${child.icon}`]" :class="'my-auto'"></font-awesome-icon>
                                        <span class="sidebar-text my-auto">{{ getTranslatedValue(child.name) }} </span>
                                    </router-link>
                                </li>
                            </span>
                        </ul>
                    </div>
                </div>

                <router-link v-else :to="setRouterLink(mainMenu)" data-toggle="collapse" aria-expanded="false" class="d-inline-flex w-100">
                    <font-awesome-icon :icon="['fas', `${mainMenu.icon}`]" :class="'my-auto'"></font-awesome-icon>
                    <span class="sidebar-text my-auto">{{ getTranslatedValue(mainMenu.name) }} </span>
                </router-link>
            </li>
        </span>
    </ul>
</template>

<script>
    import {computed, onMounted, reactive, watch} from "vue";
    import {useStore} from "vuex";
    import {useRoute} from "vue-router";

    export default {
        name: "SideBar",
        data() {
            return {
                companyURL: ['company', 'create-company', 'company-details'],
            }
        },
        setup() {
            const store = useStore();
            const route = useRoute();
            let data = reactive({
                targetedIndex: false
            });

            onMounted(() => {
                // let collapseOne = document.getElementById(`collapseOne-${data.targetedIndex}`);
                // if (collapseOne) collapseOne.classList.add('show');
            })

            function formatName(name) {
            const lowerCaseName = name.toLowerCase();
        
            if (lowerCaseName.includes('_')) {
                return lowerCaseName;
            } else {
                return lowerCaseName.replace(' ', '_'); 
            }
        }

            function getTranslatedValue(name) {
                const formattedName = formatName(name);
                
                const translations = computed(() => {
                    return store.state.translations;  
                });

                return translations.value[formattedName] || name;
            }

            let currentMenu = computed(() => store.getters.getSelectedMenu ? store.getters.getSelectedMenu : []);

            const isRouteActive = (index, routeObj) => {
                if (route.path === routeObj.route_link || route.name === routeObj.route_link) {
                    data.targetedIndex = index;
                    return 'active';
                }
                return ''
            }
            function setRouterLink(params) {
                const currentId = route.params.id ? route.params.id : null
                if ((params.parent_id == 3 || params.parent_id == 12 || params.parent_id == 17) && currentId) {
                    return { name:params.route_link, params: { id: currentId } }
                } else {
                    return `${params.route_link}`
                }
            }

            return {
                currentMenu,
                isRouteActive, setRouterLink,getTranslatedValue
            }
        }

    }
</script>

<style scoped>

</style>
