<template>
    <div>
        <!-- Chatbox -->
        <section class="chatbox-wrapper rounded shadow-lg">
            <div class="chatbox-popup chatbox-collapsed d-flex flex-column shadow-sm border-0" id="chatbox">
                <div class="chatbox-header rounded-top d-inline-flex w-100 justify-content-between align-items-center text-white px-3 py-2">
                    <div class="d-flex align-items-center justify-content-between chat-header-content">
                        <div class="position-relative pe-2">
                            <div class="avatar-wrapper rounded-circle">
                                <img :src="require('@/assets/img/profile-avatar.png')" alt="" class="chatbox-avatar" />
                            </div>
                            <span class="position-absolute user-online-status small-text">
                                <font-awesome-icon :icon="['fas', `circle`]" :class="'active-status'"></font-awesome-icon>
                            </span>
                        </div>
                        <div class="chatbox-header-text text-white d-flex flex-column flex-fill">
                            <h6 class="pb-1 chatbox-header-text text-break">{{ getProjectData.project_name ? getProjectData.project_name : 'NONE' }}</h6>
                            <span class="small-text chatbox-header-text">
                                <font-awesome-icon :icon="['fas', `user-check`]" :class="'active-status'"></font-awesome-icon>
                                 Currently Active: 56
                            </span>
                        </div>
                    </div>
                    <span class="chat-btn-close" id="chat-close-btn" @click="destroy">
                        <font-awesome-icon :icon="['fas', `times`]"></font-awesome-icon>
                    </span>
                </div>

                <div class="chatbox-body chatbox-body-width pb-2 d-flex flex-column"
                    v-on:scroll="handleScrolledTopToBottom" @click="showEmoji('body')">
                    <div class="w-100 px-2" :class="list.myself? 'sent-wrapper' : 'received-wrapper'"
                         v-for="(list, index) in data.messageList" :key="index">

                        <div class="d-flex align-items-end my-2" data-bs-toggle="tooltip" :title="list.participantName"
                             :class="list.myself? 'message-sent justify-content-end' : 'message-received justify-content-start'">
                            <img v-if="!list.myself && list.participantPhoto" alt="" class="chat-avatar"
                                 :src="list.participantPhoto"/>
                            <span v-else-if="!list.myself && !list.participantPhoto"
                                class="profile-name-avatar">
                                {{ list.participantName.match(/\b(\w)/g).join('') }}</span>

                            <!-- FOR TEXT -->
                            <p class="message-text text-break w-75 p-2 rounded-3" v-if="list.type == 'text'">
                                {{ list.content }}
                            </p>
                            <!-- FOR FILE -->
                            <a target="_blank" :href="`${list.content}`" 
                                class="message-text text-break w-75 p-2 rounded-3 d-inline-flex align-items-center" v-else-if="list.type == 'file'">
                                <span>
                                    {{ list.content }}
                                </span>
                                <span class="text-right ms-auto">
                                    <font-awesome-icon :icon="['fas', 'download']" :class="'download-file'"></font-awesome-icon>
                                </span>
                            </a>
                            <!-- FOR IMAGE -->
                            <div v-else-if="list.type == 'image'" class="chatbox-image d-flex align-items-center rounded">
                                <img :src="`${list.content}`" :alt="list.content">
                            </div>

                        </div>

                        <span v-if="!list.myself" class="small-text d-block text-start">Received - {{ list.timestamp }}</span>
                        <span v-else class="small-text d-block w-100 text-end">Sent - {{ list.timestamp }}</span>
                    </div>

                </div>

                <span class="typing w-100 p-2 typing-div mt-auto" v-if="data.msgTyping">
                    {{ data.msgTyping }}
                </span>

                <form @submit.prevent="submitForm" class="chatbox-form">

                    <div class="emoji-wrapper w-100" v-if="data.showEmojiDiv">
                    </div>
                    
                    <div class="form-content-wrapper rounded-3 d-flex flex-column text-white">

                        <!-- SHOW MESSAGE FOR FILE ATTACH -->
                        <div class="upload-file-wrapper pt-2 px-2" v-if="data.getRequestFile['file']"> 
                            <span class="uploaded-file px-2 d-inline-flex align-items-center w-100">
                                <font-awesome-icon :icon="['fas', 'check-circle']" :class="'icon-font-size times-circle-icon'"></font-awesome-icon>
                                <small class="text-white px-2 text-break">{{ data.getRequestFile['file'] ? data.getRequestFile['file'][0].name : 'Please upload file' }}</small>
                                <font-awesome-icon :icon="['fas', 'times-circle']" @click="removeFile('file')"
                                :class="'ms-auto text-danger icon-font-size times-circle-icon'"></font-awesome-icon>
                            </span>
                            <small v-if="data.showAttachmentError" class="text-warning">{{ data.showAttachmentError }}</small>
                        </div>

                        <!-- CHAT BOX INPUT FIELDS -->
                        <div class="chatbox-text p-2 d-inline-flex my-auto">
                            <div class="w-100 rounded-3 p-2 d-flex bg-white">

                                <textarea type="text" placeholder="Type your message"
                                    class="w-75 h-100 d-none message-input form-control form-control-sm my-auto"
                                    v-model="data.inputFormData.content"
                                    @keyup="startTypingProcess"
                                    @focusout="endTypingEvent"
                                    rows="2" required></textarea>
                           
                                <div class="chatbox-addons d-flex ps-2 py-2 my-auto">
                                    <div class="pe-3 chat-addon">
                                        <input type="file" id="upload" name="file" @change="getFormFile($event, 'file')" hidden/>
                                        <label for="upload">
                                            <span class="icon-font-size"><font-awesome-icon :icon="['fas', `paperclip`]"></font-awesome-icon></span>
                                        </label>
                                    </div>
                                    <div class="pe-3 chat-addon" @click="showEmoji('emoji')">
                                        <span class="emo-btn icon-font-size" type="button">
                                            <font-awesome-icon :icon="['fas', `grin`]"></font-awesome-icon>
                                        </span>
                                    </div>  
                                </div>

                                <span type="submit" @click="submitForm" class="d-none btn-send icon-font-size text-center my-auto">
                                    <font-awesome-icon :icon="['fas', 'play']"></font-awesome-icon>
                                </span>

                            </div>
                        </div>
                    </div>
                </form>

            </div>
        </section>

        <div type="button" class="btn btn-transparent chatbox-toggle-btn" >
            <span class="position-relative rounded-circle" id="chatbox-toggle" >
               <font-awesome-icon v-if="toggleButton.showIcon"
                    :icon="['fas', `${toggleButton.icon}`]"></font-awesome-icon>
               <span v-if="toggleButton.count" class="text-white position-absolute rounded message-count">{{ data.totalMessage.total }}</span>
            </span>
        </div>
    </div>
</template>


<script>
    import Pusher from "pusher-js";
    import {ref, reactive, computed, onMounted, inject } from "vue";
    import { useStore } from "vuex";
    import { useRoute } from "vue-router";
    import HelperFunction from "@/common/helpers";
    import Cookies from 'js-cookie'

    export default {
        name: "ChatBox",
        // components: {
        //     VuemojiPicker
        // },
        setup() {
            const { data, getParams, storeFormData, getFormFile, debounceEvent } = HelperFunction();
            const store = useStore();
            const route = useRoute();
            const axios = inject('axios')
            const message = ref("");
            let msgTyping = ref("");
            let toggleButton = reactive({
                count: true,
                showIcon: true,
                icon: 'comments'
            })
            let setParams = {
                project_id: route.params.id,
                limit: 10,
                page: 1
            };
            let lastPage = 1;
            let pusher;
            data.onScrollCreateAxios = false
            data.showEmojiDiv = false

            data.setURL = vueConfig.Project.DiscussionEndPoint;
            data.msgTyping = "";
            data.messageList = [];
            data.totalMessage = [];

            /** Computed Properties**/
            const loggedInUser = computed(() => store.getters.getProfile);
            const selectedCompany = computed(() => store.getters.getCompany);
            const getProjectData = computed(() => store.getters.getProjectData);

            onMounted(() => {
                callChatBoxFunctions()
                loadMessage()
            })

            function handleEmoji (EmojiClickEventDetail) {
                data.inputFormData.content = typeof(data.inputFormData.content) == 'undefined' ? '' : data.inputFormData.content
                data.inputFormData.content += EmojiClickEventDetail.unicode
            }

            function loadMessage() {
                const params = getParams(setParams)
                const conditionalAxios = data.onScrollCreateAxios ? axios.create() : axios
                conditionalAxios.get(data.setURL, params)
                    .then((response) => {
                        data.totalMessage = response.data.data
                        lastPage = data.totalMessage.last_page
                        data.messageList.unshift(...data.totalMessage.data.reverse());
                    })
                    .catch( async (err) => {
                        // CALL REFRESH TOKEN ACTION
                        const originalRequest = err.config;
                        if(data.onScrollCreateAxios && err.response && err.response.status === 401 && !originalRequest._retry) {
                            originalRequest._retry = true
                            const unInterceptedAxiosInstance = axios.create()
                            let refreshToken = Cookies.get("refresh_token")
                            try {
                                const resp = await store.dispatch("refreshAction", { formData: refreshToken, action: "refresh" })
                                
                                if (resp.status === 200) {
                                    originalRequest.headers['Authorization'] = Cookies.get("access_token")
                                    return unInterceptedAxiosInstance(originalRequest)
                                }
                            } catch (_error) {
                                return Promise.reject(_error)
                            }
                        }
                        console.error(err)
                    })
            }
            function handleScrolledTopToBottom(e) {
                if (e.target.scrollTop <= 0 && setParams.page < lastPage) {
                    e.target.scrollTop = 50;
                    setParams.page++;
                    data.onScrollCreateAxios = true
                    loadMessage();
                }
            }

            // START TYPING EVENT
            function startTypingEvent () {
                storeFormData({ url: `${data.setURL}/typing` }, { username: loggedInUser.value.name, id: setParams.project_id },
                false, false, false, true, false)
            }
            // DEBOUNCE REQUEST FOR 500ms
            const startTypingProcess = debounceEvent(() => startTypingEvent())

            // END TYPING EVENT
            const endTypingEvent = () => {
                storeFormData({ url: `${data.setURL}/typing` }, { username: "", id: setParams.project_id },
                    false, false, false, true, false)
            }

            const submitForm = async () => {
                data.inputFormData.company_id = selectedCompany.value.id;
                data.inputFormData.project_id = setParams.project_id;
                // SET FILE TYPE OR TEXT
                if (data.getRequestFile['file'] && data.getRequestFile['file'][0].type.match('image'))
                    data.inputFormData.type = 'image'
                else if (data.getRequestFile['file'] && data.getRequestFile['file'][0].type.match('application'))
                    data.inputFormData.type = 'file'

                await storeFormData({ url: data.setURL }, data.inputFormData, false, false, false, false, false)
                .then(() => {
                    if (data.getRequestFile['file']) {
                        data.totalMessage.total++
                    }
                    if (data.inputFormData.content) {
                        data.totalMessage.total++
                    }
                    data.showEmojiDiv = false
                    data.inputFormData.content = "";
                    removeFile('file')
                })
                .catch ((error) => {
                    if (error && error.response.data)
                        data.showAttachmentError = error.response.data.errors.file[0]
                })
            };

            const removeFile = (fileName) => {
                if (data.getRequestFile[fileName] != null) {
                    delete data.getRequestFile[fileName]
                    data.showAttachmentError = ''
                }
            }

            function showEmoji(position) {
                if (position == 'emoji')
                    data.showEmojiDiv = !data.showEmojiDiv
                else if (position == 'body')
                    data.showEmojiDiv = false
            }

            function callChatBoxFunctions() {
                const chatBoxToggle = document.getElementById("chatbox-toggle");
                const chatBox = document.getElementById("chatbox");
                const chatCloseBtn = document.getElementById("chat-close-btn");
                const chatBoxBody = document.querySelector(".chatbox-body");
                const chatBoxInput = document.querySelector(".message-input");
                const sendBtn = document.querySelector(".btn-send");
                // scroll starts from bottom
                chatBoxBody.scrollTop = chatBoxBody.scrollHeight;

                function changeToggleIcon () {
                    if (chatBox.classList.contains("chatbox-collapsed")) {
                        toggleButton.icon = 'comments';
                        toggleButton.count = true;
                        toggleButton.showIcon = true;
                    } else {
                        toggleButton.showIcon = false;
                        toggleButton.count = false;

                        // Initialize pusher
                        Pusher.logToConsole = true;
                        pusher = new Pusher(`${process.env.VUE_APP_PUSHER_KEY}`, {
                            cluster: `${process.env.VUE_APP_PUSHER_CLUSTER}`,
                        });

                        const chat = pusher.subscribe("chat-" + setParams.project_id);
                        chat.bind("message", (response) => {
                            // console.error(`%c chat message `, "background: #222; color: #bada55", response);
                            response.discussion.myself = loggedInUser.value.name == response.discussion.participantName ? true : false
                            data.messageList.push(response.discussion);
                            data.msgTyping = "";

                            // scroll starts from bottom
                            chatBoxBody.scrollTop = chatBoxBody.scrollHeight;
                        });

                        const typing = pusher.subscribe("typing-" + setParams.project_id);
                        typing.bind("message", (response) => {
                            if (!response.message.includes(loggedInUser.value.name)) {
                                // console.error(`%c chat message typing `, "background: #222; color: #bada55", response);
                                data.msgTyping = response.message;

                                // scroll starts from bottom
                                chatBoxBody.scrollTop = chatBoxBody.scrollHeight;
                            }
                        });
                    }
                }
                chatBoxToggle.addEventListener("click", () => {
                    chatBox.classList.toggle("chatbox-collapsed");
                    chatBoxInput.classList.toggle("d-none");
                    sendBtn.classList.toggle("d-none");
                    changeToggleIcon();
                    // scroll starts from bottom
                    chatBoxBody.scrollTop = chatBoxBody.scrollHeight;
                });
                
                chatCloseBtn.addEventListener("click", () => {
                    if (!chatBox.classList.contains("chatbox-collapsed")) {
                        chatBox.classList.add("chatbox-collapsed");
                        chatBoxInput.classList.add("d-none");
                        sendBtn.classList.add("d-none");
                        changeToggleIcon();
                    } else return;
                });
            }

            const destroy = () => {
                if (pusher) {
                    // console.error(`%c destroy `, "background: #222; color: #bada55");
                    pusher.unsubscribe("chat-" + setParams.project_id);
                    pusher.unsubscribe("typing-" + setParams.project_id);
                }                
            };

            return {
                toggleButton, data, message, msgTyping, getProjectData, startTypingProcess, 
                handleEmoji, submitForm, startTypingEvent, destroy, handleScrolledTopToBottom, 
                endTypingEvent, getFormFile, removeFile, showEmoji
            }
        },

    }


</script>

<style src="@/assets/css/chatbox.css"></style>